import { FC, useContext } from 'react';
import { SelectProps, MenuItem, Select, useTheme } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { IntakeThemeContext, RenderLabelFromSelect } from 'ui-components';
import { QuestionnaireItemAnswerOption } from 'fhir/r4';
import { useTranslation } from 'react-i18next';

type SelectInputProps = {
  name: string;
  value: string | undefined;
  options: QuestionnaireItemAnswerOption[];
  placeholder?: string;
} & SelectProps;

const SelectInput: FC<SelectInputProps> = ({ name, value, options, placeholder, ...otherProps }) => {
  const theme = useTheme();
  const { otherColors } = useContext(IntakeThemeContext);
  const { t } = useTranslation();

  return (
    <Select
      id={name}
      labelId={`${name}-label`}
      IconComponent={ExpandMore}
      displayEmpty
      value={value ?? ''}
      {...otherProps}
      fullWidth
      disableUnderline
      // To stop it adding a padding-right on the main element, shifting the background image
      MenuProps={{ disableScrollLock: true, PaperProps: { style: { maxHeight: 400 } } }}
      sx={{
        '& .MuiInputBase-input': {
          borderRadius: '8px',
          backgroundColor: theme.palette.background.paper,
          border: '1px solid',
          borderColor: otherColors.lightGray,
          padding: '10px 26px 10px 12px',
          '&:focus': {
            borderRadius: '8px',
            backgroundColor: theme.palette.background.paper,
          },
          '& p': {
            whiteSpace: 'normal',
          },
        },
        '& .MuiSelect-icon': {
          marginRight: '10px',
        },
        '& .MuiSelect-iconOpen': {
          marginRight: '10px',
        },
      }}
      renderValue={(selected: any) => {
        if (!selected) {
          return (
            <RenderLabelFromSelect styles={{ color: otherColors.placeholder }}>
              {placeholder || t('paperworkPages.Select...')}
            </RenderLabelFromSelect>
          );
        }
        return <RenderLabelFromSelect>{selected as string}</RenderLabelFromSelect>;
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option.id ?? option.valueString ?? ''}
          value={option.valueString ?? ''}
          sx={{
            whiteSpace: 'normal',
          }}
        >
          {t(`paperworkPages.${option.valueString}`, { keySeparator: '.', nsSeparator: '.' })}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectInput;
