import { EditOutlined } from '@mui/icons-material';
import { IconButton, Table, TableBody, TableCell, TableRow, Tooltip, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom';
import { ErrorDialog, ErrorDialogConfig, PageForm } from 'ui-components';
import { IntakeFlowPageRoute, bookingBasePath } from '../App';
import { UCContainer } from '../components';
import { useIntakeCommonStore } from '../features/common';
import { safelyCaptureException } from '../helpers/sentry';
import { useTrackMixpanelEvents } from '../hooks/useTrackMixpanelEvents';
import mixpanel from 'mixpanel-browser';
import { VisitType, APPOINTMENT_CANT_BE_IN_PAST_ERROR, APIError } from 'utils';
import {
  NO_LOCATION_ERROR,
  NO_PATIENT_ERROR,
  NO_PATIENT_ERROR_ID,
  NO_SLOT_ERROR,
  NO_SLOT_ERROR_ID,
  getStartingPath,
  PAST_APPT_ERROR,
  PAST_APPT_ERROR_ID,
} from '../helpers';
import { zapehrApi } from '../api';
import { useUCZambdaClient } from '../hooks/useUCZambdaClient';
import { useBookingContext } from './Welcome';
import { ReactTagManager } from 'react-gtm-ts';
import { useGetFullName } from '../hooks/useGetFullName';
import { useTranslation } from 'react-i18next';
import i18n from '../lib/i18n';
import { getLocaleDateTimeString } from '../helpers/dateUtils';

interface ReviewItem {
  name: string;
  valueString: string | undefined;
  path?: string;
}

const Review = (): JSX.Element => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    patientInfo,
    unconfirmedDateOfBirth,
    selectedLocation,
    visitType,
    selectedSlot: appointmentSlot,
    setPatientInfo,
    completeBooking,
  } = useBookingContext();
  const [errorConfig, setErrorConfig] = useState<ErrorDialogConfig | undefined>(undefined);
  const patientFullName = useGetFullName(patientInfo);
  const theme = useTheme();

  const { state: stateParam, slug: slugParam } = useParams();
  const { t } = useTranslation();

  const zambdaClient = useUCZambdaClient({ tokenless: false });
  const selectedSlotTimezoneAdjusted = useMemo(() => {
    const selectedAppointmentStart = appointmentSlot;
    if (selectedAppointmentStart && selectedLocation?.timezone) {
      return DateTime.fromISO(selectedAppointmentStart)
        .setZone(selectedLocation?.timezone)
        .setLocale('en-us');
    }

    return undefined;
  }, [appointmentSlot, selectedLocation?.timezone]);

  useTrackMixpanelEvents({
    eventName: 'Review',
    visitType: visitType,
    bookingCity: selectedLocation?.address?.city,
    bookingState: selectedLocation?.address?.state,
  });

  const onSubmit = async (): Promise<void> => {
    try {
      if (!patientInfo || !selectedLocation?.id || !visitType || !appointmentSlot) {
        // we expect this only happens for walkins
        // https://github.com/masslight/pmp-intake/issues/3097
        if (!selectedLocation) {
          console.log('no selected location error');
          safelyCaptureException(new Error('selected location missing at appointment submit time'));
          setErrorConfig(NO_LOCATION_ERROR(t));
          return;
        } else if (!patientInfo) {
          console.log('no patient info error');
          safelyCaptureException(new Error('Patient not selected at appointment submit time'));
          setErrorConfig(NO_PATIENT_ERROR(t));
          return;
        } else if (visitType === VisitType.PreBook && !appointmentSlot) {
          console.log('no slot error');
          safelyCaptureException(new Error('Slot not selected at appointment submit time'));
          setErrorConfig(NO_SLOT_ERROR(t));
          return;
        }
      }
      // Validate inputs
      if (!zambdaClient) {
        throw new Error('zambdaClient is not defined');
      }
      setLoading(true);

      // Create the appointment
      const res = await zapehrApi.createAppointment(zambdaClient, {
        slot: visitType === VisitType.WalkIn ? undefined : appointmentSlot,
        patient: patientInfo,
        location: selectedLocation?.id,
        visitType: visitType,
        unconfirmedDateOfBirth: unconfirmedDateOfBirth,
        language: 'en', // replace with i18n.language to enable
      });
      const { appointment, fhirPatientId, ecwPatientId } = res;
      const fhirAppointmentId = appointment;
      const patientType = ecwPatientId ? 'Returning' : 'New';

      // Track new or returning patient event in Mixpanel
      const locationCity = selectedLocation?.address?.city ?? '';
      const locationState = selectedLocation?.address?.state ?? '';
      mixpanel.track('UC Visit Booked', {
        uc_bookingcity_evt: locationCity,
        uc_bookingstate_evt: locationState,
        visit_type_evt: patientType,
        visit_status_evt: 'Not Complete',
        fhir_visit_id: fhirAppointmentId,
        fhir_patient_id: fhirPatientId,
        ecw_id: ecwPatientId,
      });
      ReactTagManager.action({
        event: 'conversion_event_book_appointment',
      });
      navigate(`/visit/${fhirAppointmentId}`);
      completeBooking();
    } catch (err) {
      if ((err as APIError)?.code === APPOINTMENT_CANT_BE_IN_PAST_ERROR.code) {
        setErrorConfig(PAST_APPT_ERROR(t));
      } else {
        // Catch validation errors
        console.error(err);
        useIntakeCommonStore.setState({ networkError: true });
      }
    } finally {
      setLoading(false);
    }
  };

  const reviewItems: ReviewItem[] = [
    {
      name: t('reviewAndSubmit.patient'),
      valueString: patientFullName ? patientFullName : 'Unknown',
    },
    {
      name: t('reviewAndSubmit.office'),
      valueString: selectedLocation ? `${selectedLocation?.name}` : 'Unknown',
    },
  ];

  if (visitType === VisitType.PreBook) {
    reviewItems.push({
      name: t('reviewAndSubmit.checkInTime'),
      valueString: getLocaleDateTimeString(selectedSlotTimezoneAdjusted, 'medium', i18n.language),
      path: getStartingPath(selectedLocation, visitType ?? ''),
    });
  } else if (visitType === VisitType.WalkIn) {
    reviewItems.push({
      name: t('reviewAndSubmit.walkInTime'),
      valueString: getLocaleDateTimeString(DateTime.now(), 'medium', i18n.language),
    });
  }

  const checkIfNew = (): void => {
    if (patientInfo?.newPatient) {
      setPatientInfo({ ...patientInfo, id: 'new-patient' });
    }
  };

  return (
    <UCContainer
      title={t('reviewAndSubmit.title')}
      description={t('reviewAndSubmit.subtitle')}
      bgVariant={IntakeFlowPageRoute.Review.path}
    >
      <Typography variant="h3" color="primary.main" marginTop={2} marginBottom={0}>
        {t('reviewAndSubmit.visitDetails')}
      </Typography>
      <Table
        sx={{
          marginBottom: 2,
          tr: {
            td: {
              borderBottom: '1px solid #E3E6EF',
            },
            '&:last-child': {
              td: {
                borderBottom: 'none',
              },
            },
          },
        }}
      >
        <TableBody>
          {reviewItems.map((item) => (
            <TableRow key={item.name}>
              <TableCell sx={{ paddingTop: 2, paddingBottom: 2, paddingLeft: 0, paddingRight: 0 }}>
                <Typography color="secondary.main">{item.name}</Typography>
              </TableCell>
              <TableCell padding="none" align="right">
                {item.valueString !== undefined && item.valueString}
              </TableCell>
              <TableCell padding="none" sx={{ paddingLeft: 1 }}>
                {item.path && (
                  <Tooltip title={t('reviewAndSubmit.edit')} placement="right" className="edit-slot">
                    <Link to={item.path} state={{ reschedule: true }} onClick={checkIfNew}>
                      <IconButton aria-label="edit" color="primary">
                        <EditOutlined />
                      </IconButton>
                    </Link>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography color={theme.palette.text.secondary}>
        {t('reviewAndSubmit.byProceeding')}
        <Link to="https://pmpediatriccare.com/privacy-policy/" target="_blank">
          {t('reviewAndSubmit.privacyPolicy')}
        </Link>{' '}
        {t('reviewAndSubmit.andPrivacyPolicy')}
        <Link to="https://pmpediatriccare.com/terms-and-conditions/" target="_blank">
          {t('reviewAndSubmit.termsAndConditions')}
        </Link>
        .
      </Typography>
      <PageForm
        controlButtons={useMemo(
          () => ({
            submitLabel:
              visitType === VisitType.PreBook ? t('reviewAndSubmit.reserveButton') : t('reviewAndSubmit.confirmButton'),
            loading: loading,
          }),
          [loading, t, visitType]
        )}
        onSubmit={onSubmit}
      />
      <ErrorDialog
        open={errorConfig != undefined}
        title={errorConfig?.title ?? ''}
        description={errorConfig?.description ?? ''}
        closeButtonText={errorConfig?.closeButtonText ?? t('reviewAndSubmit.ok')}
        handleClose={() => {
          if (
            errorConfig?.id === NO_PATIENT_ERROR_ID ||
            errorConfig?.id === NO_SLOT_ERROR_ID ||
            errorConfig?.id === PAST_APPT_ERROR_ID
          ) {
            if (stateParam && slugParam && visitType) {
              const basePath = generatePath(bookingBasePath, {
                state: stateParam,
                slug: slugParam,
                visit_type: visitType,
              });
              navigate(basePath);
            }
          }
          setErrorConfig(undefined);
        }}
      />
    </UCContainer>
  );
};

export default Review;
