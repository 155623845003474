import { BatchInputPostRequest, SearchParam } from '@zapehr/sdk';
import { Attachment, DocumentReference, DocumentReferenceContent } from 'fhir/r4';
import { SyncTaskIndicator } from '../../ecw-sync';
import { CreateDocucmentReferenceInput, makeSyncTask, DocRefSyncTaskInput } from '../../fhir';
import {
  FULL_INSURANCE_CARD_TITLE,
  FULL_PHOTO_ID_CARD_TITLE,
  FULL_INSURANCE_CARD_2_TITLE,
  INSURANCE_CARD_FRONT_ID,
  INSURANCE_CARD_BACK_ID,
  INSURANCE_CARD_FRONT_2_ID,
  INSURANCE_CARD_BACK_2_ID,
} from '../../types';

export interface FileDocDataForDocReference {
  url: string;
  title: string;
}

export interface CreateFileDocReferenceInput extends Omit<CreateDocucmentReferenceInput, 'docInfo' | 'references'> {
  files: FileDocDataForDocReference[];
  referenceParam: { context: DocumentReference['context']; subject?: DocumentReference['subject'] };
  searchParams: SearchParam[];
}

export async function createFilesDocumentReference(
  input: CreateFileDocReferenceInput
): Promise<DocumentReference | null> {
  const { files, type, dateCreated, referenceParam, fhirClient, pmpModule, searchParams, taskContext, generateUUID } =
    input;
  console.log('context', taskContext);
  console.log('files for doc refs', JSON.stringify(files));
  try {
    console.log('searching for current document references', JSON.stringify(searchParams));
    const docsJson = await fhirClient.searchResources<DocumentReference>({
      resourceType: 'DocumentReference',
      searchParams: [
        {
          name: 'status',
          value: 'current',
        },
        ...searchParams,
      ],
    });

    console.log('docs json', JSON.stringify(docsJson));

    // temporary solution to prevent syncing both primary and secondary when not necessary
    // should split primary and seondary into separate doc refs in the long run
    const insuranceFilesChanging: string[] = [];

    // Check if files have changed
    if (docsJson.length > 0) {
      console.log(
        'found active document references:',
        docsJson.map((doc) => doc.id)
      );
      const oldDocsTitleUrlConcats = new Set(
        docsJson.flatMap((x) =>
          x.content
            .filter(
              (content) =>
                content.attachment.title &&
                ![FULL_PHOTO_ID_CARD_TITLE, FULL_INSURANCE_CARD_TITLE, FULL_INSURANCE_CARD_2_TITLE].includes(
                  content.attachment.title
                ) // filter out documents that are the combined copy of cards
            )
            .map((content) => `${content.attachment.title || ''},${content.attachment.url || ''}`)
        )
      );
      const newDocsTitleUrlConcats = new Set(files.map((x) => `${x.title || ''},${x.url || ''}`));
      console.log("Comparing doc by it's attachment title + url pairs");
      const diff = new Set(oldDocsTitleUrlConcats);
      for (const elem of newDocsTitleUrlConcats) {
        if (diff.has(elem)) {
          diff.delete(elem);
        } else {
          diff.add(elem);
          const elemArr = elem.split(',');
          if (elemArr.includes(INSURANCE_CARD_FRONT_ID)) insuranceFilesChanging.push(INSURANCE_CARD_FRONT_ID);
          if (elemArr.includes(INSURANCE_CARD_BACK_ID)) insuranceFilesChanging.push(INSURANCE_CARD_BACK_ID);
          if (elemArr.includes(INSURANCE_CARD_FRONT_2_ID)) insuranceFilesChanging.push(INSURANCE_CARD_FRONT_2_ID);
          if (elemArr.includes(INSURANCE_CARD_BACK_2_ID)) insuranceFilesChanging.push(INSURANCE_CARD_BACK_2_ID);
        }
      }

      if (diff.size > 0) {
        console.log('Changes in files doc found');
        docsJson.forEach((oldDoc) => {
          fhirClient
            .patchResource({
              resourceType: 'DocumentReference',
              resourceId: oldDoc.id || '',
              operations: [{ op: 'replace', path: '/status', value: 'superseded' }],
            })
            .catch((error) => {
              throw new Error(`Failed to update files doc DocumentReference status: ${JSON.stringify(error)}`);
            });
          console.log(`Files doc DocumentReference ${oldDoc.id} status changed to superseded`);
        });
      } else {
        console.log('No changes in files doc found');
        // assuming that when no diff found there can be only one doc at a time that has same files
        return docsJson[0];
      }
    } else {
      files.forEach((file) => {
        if (
          [
            INSURANCE_CARD_FRONT_ID,
            INSURANCE_CARD_BACK_ID,
            INSURANCE_CARD_FRONT_2_ID,
            INSURANCE_CARD_BACK_2_ID,
          ].includes(file.title)
        )
          insuranceFilesChanging.push(file.title);
      });
    }
    const content: DocumentReferenceContent[] = [];
    console.log('processing files...');
    files.forEach((file) => {
      console.log('file title: ', file.title);
      const urlExt = file.url.split('.').slice(-1).toString();
      let contentType: string;
      switch (urlExt) {
        case 'pdf':
          contentType = 'application/pdf';
          break;
        case 'jpg':
          contentType = 'image/jpeg';
          break;
        default:
          contentType = `image/${urlExt}`;
      }
      content.push({
        attachment: {
          url: file.url,
          contentType,
          title: file.title,
        },
      });
    });

    console.log('content for writing cards DocRefs', JSON.stringify(content));

    if (content.length > 0) {
      console.log('Creating current files document reference resource', type);
      const writeDRFullUrl = generateUUID ? generateUUID() : undefined;
      const writeDocRefReq: BatchInputPostRequest = {
        method: 'POST',
        fullUrl: writeDRFullUrl,
        url: '/DocumentReference',
        resource: {
          resourceType: 'DocumentReference',
          meta: {
            tag: [{ code: pmpModule }],
          },
          status: 'current',
          type: type,
          date: dateCreated,
          content: content,
          ...referenceParam,
        },
      };

      const additionalTasks: BatchInputPostRequest[] = [];
      console.log('check insurance files changing', insuranceFilesChanging);
      if (taskContext && writeDRFullUrl) {
        console.log(`Creating task for ${type.text}`);
        const taskInput: DocRefSyncTaskInput = {
          documentRef: writeDRFullUrl,
          context: taskContext,
          taskCoding: SyncTaskIndicator.genPDFCards,
        };
        if ([INSURANCE_CARD_FRONT_ID, INSURANCE_CARD_BACK_ID].some((id) => insuranceFilesChanging.includes(id))) {
          console.log('primary insurance is being updated');
          taskInput.additionalCoding = [SyncTaskIndicator.primaryIns];
        }
        if ([INSURANCE_CARD_FRONT_2_ID, INSURANCE_CARD_BACK_2_ID].some((id) => insuranceFilesChanging.includes(id))) {
          console.log('secondary insurance is being updated');
          if (taskInput?.additionalCoding) {
            taskInput.additionalCoding.push(SyncTaskIndicator.secondaryIns);
          } else {
            taskInput.additionalCoding = [SyncTaskIndicator.secondaryIns];
          }
        }
        const writeTaskReq: BatchInputPostRequest = {
          method: 'POST',
          url: '/Task',
          resource: makeSyncTask(taskInput),
        };
        additionalTasks.push(writeTaskReq);
      }
      const results = await fhirClient.transactionRequest({ requests: [writeDocRefReq, ...additionalTasks] });
      const docRef = results.entry?.[0]?.resource;
      if (docRef?.resourceType !== 'DocumentReference') {
        throw 'failed';
      }
      return docRef;
    } else {
      console.log('No new document reference created');
      return null;
    }
  } catch (error: unknown) {
    console.log('Error writing doc refs', JSON.stringify(error));
    throw new Error(`Failed to create files DocumentReference resource: ${JSON.stringify(error)}`);
  }
}

export const addContentTypeToAttachement = (attachment: Attachment): Attachment => {
  if (attachment.contentType || !attachment.url) {
    return { ...attachment };
  }
  const urlExt = attachment.url.split('.').slice(-1).toString();
  switch (urlExt) {
    case 'pdf':
      return {
        ...attachment,
        contentType: 'application/pdf',
      };
    case 'jpg':
      return {
        ...attachment,
        contentType: 'image/jpeg',
      };
    default:
      return {
        ...attachment,
        contentType: `image/${urlExt}`,
      };
  }
};
