import { useFormContext } from 'react-hook-form';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { InputProps } from '@mui/material';
import DateInputFields from 'ui-components/lib/components/form/DateInputFields';
import { StyledQuestionnaireItem } from '../useItemStyleOverrides';
import { getComponentKeysFromDateGroup } from 'utils';
import { useFieldError } from '../useFormHelpers';

type DateInputProps = {
  item: StyledQuestionnaireItem;
  fieldId: string;
  helperText?: string;
  showHelperTextIcon?: boolean;
  required?: boolean;
} & InputProps;

const DateInput: FC<DateInputProps> = ({ item, fieldId }) => {
  const { setValue, watch, getValues, register, trigger } = useFormContext();

  const disabled = item.displayStrategy === 'protected';

  console.log('dateInputFieldId', fieldId);

  const { dayId, monthId, yearId, dayLinkId, yearLinkId, monthLinkId } = useMemo(() => {
    // these are the paths to the values manipulated by the 3 date component inputs
    const dayId = `${fieldId}.item.0.answer.0.valueString`;
    const monthId = `${fieldId}.item.1.answer.0.valueString`;
    const yearId = `${fieldId}.item.2.answer.0.valueString`;
    // these are the linkids of the nested fields on the date group questionnaire item
    const { dayKey: dayLinkId, monthKey: monthLinkId, yearKey: yearLinkId } = getComponentKeysFromDateGroup(item);
    return { dayId, monthId, yearId, dayLinkId, yearLinkId, monthLinkId };
  }, [fieldId, item]);

  const dayVal = watch(dayId, getValues(dayId) ?? '');
  const monthVal = watch(monthId, getValues(monthId) ?? '');
  const yearVal = watch(yearId, getValues(yearId) ?? '');

  const { ref: inputRef } = register(fieldId);

  const currentValue = getValues(fieldId);

  const dateError = useFieldError(fieldId).hasError;

  useEffect(() => {
    if (dateError) {
      void trigger(fieldId);
    }
  }, [dateError, item.linkId, dayVal, monthVal, yearVal, trigger, fieldId]);
  // would be nice to find a better way to do this than w/ an effect...
  useEffect(() => {
    if (currentValue == undefined || currentValue.linkId == undefined) {
      setValue(`${fieldId}.linkId`, item.linkId);
    }
  }, [currentValue, fieldId, item.linkId, setValue]);

  const setNestedValue = useCallback(
    (id: number, value: any) => {
      const path = `${fieldId}.item.${id}`;
      let linkId = dayLinkId;
      if (id === 1) {
        linkId = monthLinkId;
      } else if (id === 2) {
        linkId = yearLinkId;
      }
      setValue(path, { linkId, answer: [{ valueString: value }] });
    },
    [dayLinkId, fieldId, monthLinkId, setValue, yearLinkId]
  );

  return (
    <DateInputFields
      required={item.required}
      error={dateError}
      label={undefined}
      disabled={disabled}
      selectedDay={dayVal}
      selectedMonth={monthVal}
      selectedYear={yearVal}
      inputRef={inputRef}
      setSelectedDay={(day) => {
        setNestedValue(0, day);
      }}
      setSelectedMonth={(month) => {
        setNestedValue(1, month);
      }}
      setSelectedYear={(year) => {
        setNestedValue(2, year);
      }}
      key={`DateInput-${fieldId}`}
    />
  );
};

export default DateInput;
