import { QuestionnaireItem, QuestionnaireResponseItem } from 'fhir/r4';
import { GridSize } from '@mui/system';
import { PMQuestionnaireItem, evalEnableWhen } from 'utils';
import { _objectKeys } from 'fast-json-patch/module/helpers';

export interface StyledQuestionnaireItem extends QuestionnaireItem {
  hideControlLabel: boolean;
  width?: GridSize;
  isFullWidth?: boolean;
}

type DisplayStrategy = 'enabled' | 'hidden' | 'protected';
// this is called without all items in one place
export const getItemDisplayStrategy = (
  item: PMQuestionnaireItem,
  items: PMQuestionnaireItem[],
  values: { [itemLinkId: string]: QuestionnaireResponseItem }
): DisplayStrategy => {
  if (item.readOnly) {
    return item.disabledDisplay ?? 'hidden';
  }
  const enabled = evalEnableWhen(item, items, values);
  return enabled ? 'enabled' : item.disabledDisplay ?? 'hidden';
};

// use this prior to initializing the form to exclude some values entirely
// based on Questionnaire configuration and initial values
export const selectQuestionnaireItems = (
  pageItems: PMQuestionnaireItem[],
  allItems: PMQuestionnaireItem[],
  initialValues: { [itemLinkId: string]: QuestionnaireResponseItem }
): QuestionnaireItem[] => {
  // console.log('items values', initialValues, pageItems);
  return pageItems.filter((item) => {
    const displayStrategy = getItemDisplayStrategy(item, allItems, initialValues);
    // console.log('item, ds', item.linkId, displayStrategy);
    return displayStrategy !== 'hidden';
  });
};
