import { Address, ContactPoint, LocationHoursOfOperation } from 'fhir/r4';
import { Secrets } from '../secrets';

export const FHIR_IDENTIFIER_NPI = 'http://hl7.org/fhir/sid/us-npi';

export interface PatientBaseInfo {
  firstName?: string;
  id?: string;
  middleName?: string;
  lastName?: string;
  dateOfBirth?: string;
}
export interface FileUpload {
  [key: string]: {
    fileData: File | null;
    uploadFailed: boolean;
  };
}

export interface FileURLs {
  [key: string]: {
    localUrl?: string;
    presignedUrl?: string;
    z3Url?: string;
    imgBase64?: string;
  };
}
export interface AvailableLocationInformation {
  id: string | undefined;
  slug: string | undefined;
  name: string | undefined;
  description: string | undefined;
  address: Address | undefined;
  telecom: ContactPoint[] | undefined;
  hoursOfOperation: LocationHoursOfOperation[] | undefined;
  closures: Closure[];
  timezone: string | undefined;
  otherOffices: { display: string; url: string }[];
}

// Closure start/end format: 'M/d/yyyy'
export interface Closure {
  start: string;
  end: string;
  type: ClosureType;
}
export enum ClosureType {
  OneDay = 'one-day',
  Period = 'period',
}

export const OVERRIDE_DATE_FORMAT = 'M/d/yyyy';
export const HOURS_OF_OPERATION_FORMAT = 'TT';

export const getSlugAndStateFromLocation = (
  location: AvailableLocationInformation | undefined
): { slug: string | undefined; state: string | undefined } => {
  if (location == undefined) {
    return { slug: undefined, state: undefined };
  }
  const { slug } = location;
  const state = location.address?.state?.toLowerCase();

  return { slug, state };
};

export type FormItemType =
  | 'Text'
  | 'Select'
  | 'Radio'
  | 'Radio List'
  | 'Free Select'
  | 'Date'
  | 'Year'
  | 'File'
  | 'Photos'
  | 'Checkbox'
  | 'Header 3'
  | 'Header 4'
  | 'Description'
  | 'Button'
  | 'Date Year'
  | 'Date Month'
  | 'Date Day'
  | 'Group'
  | 'Form list'
  | 'Attachment'
  | undefined;

export type PromiseReturnType<T> = T extends Promise<infer R> ? R : never;

export interface ConsentInfo {
  HIPAA: boolean;
  consentToTreat: boolean;
  signature: string;
  fullName: string;
  relationship: string;
}
export interface ConsentSigner {
  signature: string;
  fullName: string;
  relationship: string;
}

export type UserType = 'Patient' | 'Parent/Guardian';

export interface ContactInfo {
  formUser?: UserType;
  patientNumber?: string;
  parentNumber?: string;
  patientEmail?: string;
  parentEmail?: string;
  streetAddressLine1?: string;
  streetAddressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export interface HealthcareContacts {
  ethnicity?: PatientEthnicity;
  race?: PatientRace;
  physicianFirstName?: string;
  physicianLastName?: string;
  physicianPhoneNumber?: string;
  pharmacyName?: string;
  pharmacyAddress?: string;
  pharmacyPhone?: string;
}

export const CREATED_BY_SYSTEM = 'created-by'; // exists in ehr as well

export enum PatientEthnicity {
  'Hispanic or Latino' = 'Hispanic or Latino',
  'Not Hispanic or Latino' = 'Not Hispanic or Latino',
  'Decline to Specify' = 'Decline to Specify',
}

export const PatientEthnicityCode: Record<keyof typeof PatientEthnicity, string | undefined> = {
  'Hispanic or Latino': '2135-2',
  'Not Hispanic or Latino': '2186-5',
  'Decline to Specify': 'ASKU',
};

export enum PatientRace {
  'American Indian or Alaska Native' = 'American Indian or Alaska Native',
  'Asian' = 'Asian',
  'Black or African American' = 'Black or African American',
  'Native Hawaiian or Other Pacific Islander' = 'Native Hawaiian or Other Pacific Islander',
  'White' = 'White',
  'Decline to Specify' = 'Decline to Specify',
}

export const PatientRaceCode: Record<keyof typeof PatientRace, string | undefined> = {
  'American Indian or Alaska Native': '1002-5',
  Asian: '2028-9',
  'Black or African American': '2054-5',
  'Native Hawaiian or Other Pacific Islander': '2076-8',
  White: '2106-3',
  'Decline to Specify': 'ASKU',
};

export interface ResponsiblePartyInfo {
  relationship: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  dateOfBirth: string;
  birthSex?: PersonSex;
  phoneNumber?: string;
}

export interface PhotoIdCards {
  idCardFrontUrl?: string;
  idCardBackUrl?: string;
  idCardFrontLocalUrl?: string;
  idCardBackLocalUrl?: string;
  idCardFrontPresignedUrl?: string;
  idCardBackPresignedUrl?: string;
}

export enum PersonSex {
  Male = 'male',
  Female = 'female',
  Intersex = 'other',
}

export interface ZambdaInput {
  headers: any | null;
  body: string | null;
  secrets: Secrets | null;
  requestContext: any;
}

export interface SubscriptionZambdaDetails {
  criteria: string;
  reason: string;
  event?: 'create' | 'update';
}

export type ZambdaTriggerType = 'http_open' | 'http_auth' | 'subscription' | 'cron';

export interface ValuePair {
  value: string;
  label: string;
}

export const months = [
  { value: '01', label: 'Jan' },
  { value: '02', label: 'Feb' },
  { value: '03', label: 'Mar' },
  { value: '04', label: 'Apr' },
  { value: '05', label: 'May' },
  { value: '06', label: 'Jun' },
  { value: '07', label: 'Jul' },
  { value: '08', label: 'Aug' },
  { value: '09', label: 'Sep' },
  { value: '10', label: 'Oct' },
  { value: '11', label: 'Nov' },
  { value: '12', label: 'Dec' },
];

export const AllStates: ValuePair[] = [
  { value: 'AL', label: 'AL' }, // Alabama
  { value: 'AK', label: 'AK' }, // Alaska
  { value: 'AZ', label: 'AZ' }, // Arizona
  { value: 'AR', label: 'AR' }, // Arkansas
  { value: 'CA', label: 'CA' }, // California
  { value: 'CO', label: 'CO' }, // Colorado
  { value: 'CT', label: 'CT' }, // Connecticut
  { value: 'DE', label: 'DE' }, // Delaware
  { value: 'DC', label: 'DC' },
  { value: 'FL', label: 'FL' }, // Florida
  { value: 'GA', label: 'GA' }, // Georgia
  { value: 'HI', label: 'HI' }, // Hawaii
  { value: 'ID', label: 'ID' }, // Idaho
  { value: 'IL', label: 'IL' }, // Illinois
  { value: 'IN', label: 'IN' }, // Indiana
  { value: 'IA', label: 'IA' }, // Iowa
  { value: 'KS', label: 'KS' }, // Kansas
  { value: 'KY', label: 'KY' }, // Kentucky
  { value: 'LA', label: 'LA' }, // Louisiana
  { value: 'ME', label: 'ME' }, // Maine
  { value: 'MD', label: 'MD' }, // Maryland
  { value: 'MA', label: 'MA' }, // Massachusetts
  { value: 'MI', label: 'MI' }, // Michigan
  { value: 'MN', label: 'MN' }, // Minnesota
  { value: 'MS', label: 'MS' }, // Mississippi
  { value: 'MO', label: 'MO' }, // Missouri
  { value: 'MT', label: 'MT' }, // Montana
  { value: 'NE', label: 'NE' }, // Nebraska
  { value: 'NV', label: 'NV' }, // Nevada
  { value: 'NH', label: 'NH' }, // New Hampshire
  { value: 'NJ', label: 'NJ' }, // New Jersey
  { value: 'NM', label: 'NM' }, // New Mexico
  { value: 'NY', label: 'NY' }, // New York
  { value: 'NC', label: 'NC' }, // North Carolina
  { value: 'ND', label: 'ND' }, // North Dakota
  { value: 'OH', label: 'OH' }, // Ohio
  { value: 'OK', label: 'OK' }, // Oklahoma
  { value: 'OR', label: 'OR' }, // Oregon
  { value: 'PA', label: 'PA' }, // Pennsylvania
  { value: 'RI', label: 'RI' }, // Rhode Island
  { value: 'SC', label: 'SC' }, // South Carolina
  { value: 'SD', label: 'SD' }, // South Dakota
  { value: 'TN', label: 'TN' }, // Tennessee
  { value: 'TX', label: 'TX' }, // Texas
  { value: 'UT', label: 'UT' }, // Utah
  { value: 'VT', label: 'VT' }, // Vermont
  { value: 'VA', label: 'VA' }, // Virginia
  { value: 'VI', label: 'VI' },
  { value: 'WA', label: 'WA' }, // Washington
  { value: 'WV', label: 'WV' }, // West Virginia
  { value: 'WI', label: 'WI' }, // Wisconsin
  { value: 'WY', label: 'WY' }, // Wyoming
];

export type StateCode = (typeof AllStates)[number]['value'];

export const stateCodeToFullName: Readonly<Record<StateCode, string>> = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VI: 'Virgin Islands',
  VT: 'Vermont',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export interface DateComponents {
  day: string;
  month: string;
  year: string;
}

export interface FacilityGroup {
  address: string;
  city: string;
  state: string;
  zip: string;
  tel: string;
  fax: string;
  group: string;
  npi: string;
  taxId: string;
}

export const AllFacilityGroups = [
  {
    address: '751 E 36th Ave',
    city: 'ANCHORAGE',
    state: 'AK',
    zip: '99503-4166',
    tel: '907-222-5090',
    fax: '907-222-5093',
    group: 'PM Pediatric Urgent Care Anchorage AK',
    npi: '1124558044',
    taxId: '821844965',
  },
  {
    address: '223 31 26th Avenue',
    city: 'BAYSIDE',
    state: 'NY',
    zip: '11360-1949',
    tel: '718-747-5437',
    fax: '718-225-0695',
    group: 'PM Pediatric Urgent Care Bayside NY',
    npi: '1861700353',
    taxId: '273326166',
  },
  {
    address: '5992 Coral Ridge Dr Unit B11',
    city: 'CORAL SPRINGS',
    state: 'FL',
    zip: '33076-3302',
    tel: '754-484-4767',
    fax: '754-484-4480',
    group: 'PM Pediatric Urgent Care Parkland FL',
    npi: '1174125561',
    taxId: '843679279',
  },
  {
    address: '719 Providence Hwy',
    city: 'DEDHAM',
    state: 'MA',
    zip: '02026-6832',
    tel: '781-461-6767',
    fax: '781-461-6774',
    group: 'PM Pediatric Urgent Care Dedham MA',
    npi: '1851828107',
    taxId: '821363644',
  },
  {
    address: '7401 Greenbelt Rd',
    city: 'GREENBELT',
    state: 'MD',
    zip: '20770-3402',
    tel: '301-982-5437',
    fax: '301-982-5428',
    group: 'PM Pediatric Urgent Care Greenbelt MD',
    npi: '1306238647',
    taxId: '471811550',
  },
  {
    address: '571 W Mt Pleasant Ave',
    city: 'LIVINGSTON',
    state: 'NJ',
    zip: '07039-1607',
    tel: '973-992-4767',
    fax: '973-992-4780',
    group: 'PM Pediatric Urgent Care Livingston NJ',
    npi: '1639430515',
    taxId: '454846207',
  },
  {
    address: '8731 W Pico Blvd',
    city: 'LOS ANGELES',
    state: 'CA',
    zip: '90035-2205',
    tel: '310-312-5437',
    fax: '310-312-5438',
    group: 'PM Pediatric Urgent Care LA Westside CA',
    npi: '1578030359',
    taxId: '831518198',
  },
  {
    address: '1125 Hatches Pond Ln Ste 101',
    city: 'MORRISVILLE',
    state: 'NC',
    zip: '27560-6294',
    tel: '919-467-7425',
    fax: '919-467-7412',
    group: 'PM Pediatric WakeMed Childrens Urgent Care Morrisville NC',
    npi: '1932679446',
    taxId: '831612410',
  },
  {
    address: '2249 Medical Center Pkwy Ste C & D',
    city: 'MURFREESBORO',
    state: 'TN',
    zip: '37129-4279',
    tel: '629-219-2767',
    fax: '629-219-2768',
    group: 'PM Pediatric Urgent Care Murfreesboro TN',
    npi: '1871112375',
    taxId: '843557876',
  },
  {
    address: '2835 Showplace Dr Ste 119',
    city: 'Naperville',
    state: 'IL',
    zip: '60564-5056',
    tel: '630-470-4878',
    fax: '630-870-1712',
    group: 'PM Pediatric Urgent Care Naperville IL',
    npi: '1316597164',
    taxId: '842968080',
  },
  {
    address: '3180 Fashion Center Blvd Christina Fashion Center',
    city: 'NEWARK',
    state: 'DE',
    zip: '19702-3246',
    tel: '302-500-5437',
    fax: '302-500-5439',
    group: 'PM Pediatric Urgent Care Newark DE',
    npi: '1316691371',
    taxId: '874545212',
  },
  {
    address: '6358 Springfield Plaza',
    city: 'SPRINGFIELD',
    state: 'VA',
    zip: '22150-3431',
    tel: '703-644-5437',
    fax: '703-644-6767',
    group: 'PM Pediatric Urgent Care Springfield VA',
    npi: '1962902650',
    taxId: '823857687',
  },
  {
    address: '5151 TX 121 Hwy',
    city: 'THE COLONY',
    state: 'TX',
    zip: '75056-2601',
    tel: '214-488-5437',
    fax: '214-488-5438',
    group: 'Childrens Health PM Pediatric Urgent Care The Colony TX',
    npi: '1275013955',
    taxId: '831466906',
  },
  {
    address: '101 E Swedesford Rd',
    city: 'WAYNE',
    state: 'PA',
    zip: '19087-1455',
    tel: '610-688-5437',
    fax: '610-688-3502',
    group: 'PM Pediatric Urgent Care Wayne PA',
    npi: '1780169227',
    taxId: '831424358',
  },
  {
    address: '1459A New Britain Ave',
    city: 'WEST HARTFORD',
    state: 'CT',
    zip: '06110-1659',
    tel: '860-232-5437',
    fax: '860-232-2110',
    group: 'PM Pediatric Urgent Care West Hartford CT',
    npi: '1386158467',
    taxId: '823268722',
  },
] as const;

export type StateType = (typeof AllStates extends readonly (infer TElementType)[] ? TElementType : never)['value'];
export type FacilityGroupType = (typeof AllFacilityGroups extends readonly (infer TElementType)[]
  ? TElementType
  : never)['group'];

export interface VirtualLocationBody {
  name: string;
  code?: string;
  ecwCode?: string;
  facilityGroup?: FacilityGroupType;
}

export const AllStatesToNames: {
  [value in StateType]: VirtualLocationBody;
} = {
  AL: { name: 'Telemed Alabama' },
  AK: {
    name: 'Telemed Alaska',
    code: 'AKTELE',
    ecwCode: 'AKTELE',
    facilityGroup: 'PM Pediatric Urgent Care Anchorage AK',
  },
  AZ: { name: 'Telemed Arizona' },
  AR: { name: 'Telemed Arkansas' },
  CA: {
    name: 'Telemed California',
    code: 'CATELE',
    ecwCode: 'pRJ3Jp',
    facilityGroup: 'PM Pediatric Urgent Care LA Westside CA',
  },
  CO: { name: 'Telemed Colorado' },
  CT: {
    name: 'Telemed Connecticut',
    code: 'CTTELE',
    ecwCode: 'p8yVag',
    facilityGroup: 'PM Pediatric Urgent Care West Hartford CT',
  },
  DC: { name: 'Telemed DC', code: 'DCTELE', ecwCode: 'g57b4A', facilityGroup: 'PM Pediatric Urgent Care Greenbelt MD' },
  DE: {
    name: 'Telemed Delaware',
    code: 'DETELE',
    ecwCode: 'DETELE',
    facilityGroup: 'PM Pediatric Urgent Care Newark DE',
  },
  FL: {
    name: 'Telemed Florida',
    code: 'FLTELE',
    ecwCode: '0kDw2A',
    facilityGroup: 'PM Pediatric Urgent Care Parkland FL',
  },
  GA: { name: 'Telemed Georgia' },
  HI: { name: 'Telemed Hawaii' },
  ID: { name: 'Telemed Idaho' },
  IL: {
    name: 'Telemed Illinois',
    code: 'ILTELE',
    ecwCode: 'gQJM20',
    facilityGroup: 'PM Pediatric Urgent Care Naperville IL',
  },
  IN: { name: 'Telemed Indiana' },
  IA: { name: 'Telemed Iowa' },
  KS: { name: 'Telemed Kansas' },
  KY: { name: 'Telemed Kentucky' },
  LA: { name: 'Telemed Louisiana' },
  ME: { name: 'Telemed Maine' },
  MD: {
    name: 'Telemed Maryland',
    code: 'MDTELE',
    ecwCode: '0e9Ww0',
    facilityGroup: 'PM Pediatric Urgent Care Greenbelt MD',
  },
  MA: {
    name: 'Telemed Massachusetts',
    code: 'MATELE',
    ecwCode: 'go5MMA',
    facilityGroup: 'PM Pediatric Urgent Care Dedham MA',
  },
  MI: { name: 'Telemed Michigan' },
  MN: { name: 'Telemed Minnesota' },
  MS: { name: 'Telemed Mississippi' },
  MO: { name: 'Telemed Missouri' },
  MT: { name: 'Telemed Montana' },
  NE: { name: 'Telemed Nebraska' },
  NV: { name: 'Telemed Nevada' },
  NH: { name: 'Telemed New Hampshire' },
  NJ: {
    name: 'Telemed New Jersey',
    code: 'NJTELE',
    ecwCode: '0mjM9A',
    facilityGroup: 'PM Pediatric Urgent Care Livingston NJ',
  },
  NM: { name: 'Telemed New Mexico' },
  NY: {
    name: 'Telemed New York',
    code: 'NYTELE',
    ecwCode: 'p8yq4g',
    facilityGroup: 'PM Pediatric Urgent Care Bayside NY',
  },
  NC: {
    name: 'Telemed North Carolina',
    code: 'NCTELE',
    ecwCode: 'A246Gk',
    facilityGroup: 'PM Pediatric WakeMed Childrens Urgent Care Morrisville NC',
  },
  ND: { name: 'Telemed North Dakota' },
  OH: { name: 'Telemed Ohio' },
  OK: { name: 'Telemed Oklahoma' },
  OR: { name: 'Telemed Oregon' },
  PA: {
    name: 'Telemed Pennsylvania',
    code: 'PATELE',
    ecwCode: 'A47Rl0',
    facilityGroup: 'PM Pediatric Urgent Care Wayne PA',
  },
  RI: { name: 'Telemed Rhode Island' },
  SC: { name: 'Telemed South Carolina' },
  SD: { name: 'Telemed South Dakota' },
  TN: {
    name: 'Telemed Tennessee',
    code: 'TNTELE',
    ecwCode: 'TNTELE',
    facilityGroup: 'PM Pediatric Urgent Care Murfreesboro TN',
  },
  TX: {
    name: 'Telemed Texas',
    code: 'TXTELE',
    ecwCode: 'A9lKa0',
    facilityGroup: 'Childrens Health PM Pediatric Urgent Care The Colony TX',
  },
  UT: { name: 'Telemed Utah' },
  VT: { name: 'Telemed Vermont' },
  VI: { name: 'Telemed Virgin Islands' },
  VA: {
    name: 'Telemed Virginia',
    code: 'VATELE',
    ecwCode: 'gLLKEg',
    facilityGroup: 'PM Pediatric Urgent Care Springfield VA',
  },
  WA: { name: 'Telemed Washington' },
  WV: { name: 'Telemed West Virginia' },
  WI: { name: 'Telemed Wisconsin' },
  WY: { name: 'Telemed Wyoming' },
};
