import { Auth0Provider } from '@auth0/auth0-react';
import hasOwn from 'object.hasown';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTagManager } from 'react-gtm-ts';
import App from './App';
import './index.css';
import './lib/i18n';

// polyfill for fixing missing hasOwn Object property in some browsers
// https://www.npmjs.com/package/object.hasown
if (!Object.hasOwn) {
  hasOwn.shim();
}

const tagMgrArgs = {
  code: import.meta.env.VITE_APP_GTM_ID,
};

ReactTagManager.init(tagMgrArgs);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
if (!import.meta.env.VITE_APP_CLIENT_ID || !import.meta.env.VITE_APP_AUTH0_AUDIENCE) {
  throw new Error('Client ID or audience not found');
}
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={import.meta.env.VITE_APP_AUTH_URL}
      clientId={import.meta.env.VITE_APP_CLIENT_ID}
      connection="sms"
      redirectUri={`${window.location.origin}/redirect`}
      audience={import.meta.env.VITE_APP_AUTH0_AUDIENCE}
      // adding cache location so that auth persists on page refresh
      // https://stackoverflow.com/questions/63537913/auth0-does-not-persist-login-on-page-refresh-for-email-password
      cacheLocation="localstorage"
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);
