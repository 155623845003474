import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputBaseComponentProps,
  InputProps,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
  Button,
} from '@mui/material';
import { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FieldValues, FormProvider, useForm, useFormContext } from 'react-hook-form';
import {
  BoldPurpleInputLabel,
  ControlButtons,
  ControlButtonsProps,
  DescriptionRenderer,
  InputMask,
  LightToolTip,
  LinkRenderer,
  useIntakeThemeContext,
} from 'ui-components';
import {
  PMQuestionnaireItem,
  QuestionnaireFormFields,
  SIGNATURE_FIELDS,
  makeValidationSchema,
  pickFirstValueFromAnswerItem,
  PaperworkPatient,
} from 'utils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { StyledQuestionnaireItem, applyItemStyleOverrides } from './useItemStyleOverrides';
import SelectInput from './components/SelectInput';
import RadioInput from './components/RadioInput';
import RadioListInput from './components/RadioListInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectQuestionnaireItems } from './useSelectItems';
import { usePaperworkContext } from '../../pages/PaperworkPage';
import DateInput from './components/DateInput';
import FileInput from './components/FileInput';
import Markdown from 'react-markdown';
import { useBeforeUnload } from 'react-router-dom';
import { getInputTypeForItem, inputTypeForDisplayItemId } from './utils';
import { getPaperworkFieldId, useFieldError, usePaperworkFormHelpers } from './useFormHelpers';
import { useAutoFillValues } from './useAutofill';
import { AnyObjectSchema } from 'yup';
import { FieldHelperText } from './components/FieldHelperText';
import { getUCInputType } from '../../helpers/paperworkUtils';
import { useTranslation } from 'react-i18next';
import i18n from '../../lib/i18n';
import { PaperworkContext } from '../../pages/PaperworkPage';

type Appointment = PaperworkContext['appointment'];

interface PaperworkGroupOptions {
  bottomComponent?: ReactElement;
  hideControls?: boolean;
  controlButtons?: ControlButtonsProps;
}

interface PaperworkGroupInput {
  items: PMQuestionnaireItem[];
  pageId: string;
  defaultValues?: QuestionnaireFormFields;
  options?: PaperworkGroupOptions;
  onSubmit: (data: QuestionnaireFormFields) => void;
  saveProgress: (data: QuestionnaireFormFields) => void;
}

type FormInputProps = {
  format?: string;
  helperText?: string;
  showHelperTextIcon?: boolean;
  inputBaseProps?: InputBaseComponentProps;
} & InputProps;

interface ItemInputProps {
  item: PMQuestionnaireItem;
  inputProps?: FormInputProps;
  sx?: SxProps<Theme>;
}

interface StyledItemInputProps extends ItemInputProps {
  item: StyledQuestionnaireItem;
}

const DEFAULT_INPUT_BASE_PROPS: InputBaseComponentProps = {
  width: '100%',
};

const makeFormInputPropsForItem = (item: StyledQuestionnaireItem): FormInputProps => {
  const { mask } = item;

  const inputProps = {
    format: undefined,
    infoText: undefined,
    helperText: undefined,
    showHelperTextIcon: false,
    inputBaseProps: {
      ...DEFAULT_INPUT_BASE_PROPS,
      mask,
    },
  };

  // (`input props for item ${item.linkId}`, inputProps);
  return inputProps;
};

const makeFormErrorMessage = (items: PMQuestionnaireItem[], errors: any): string | undefined => {
  const errorKeys = Object.keys(errors);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  let numErrors = errorKeys.length;
  if (numErrors === 0) {
    return undefined;
  }
  const errorItems = items
    .filter((i) => errorKeys.includes(i.linkId) && (i.text !== undefined || i.type === 'group'))
    .flatMap((i) => {
      if (i.type === 'group' && i.dataType !== 'DOB') {
        const items = ((errors[i.linkId] as any)?.item ?? []) as any[];
        const internalErrors: PMQuestionnaireItem[] = [];
        items.forEach((e, idx) => {
          if (e != null) {
            const errorItem = (i.item ?? []).filter((i) => i.type !== 'display' && !i.readOnly)[idx];
            if (errorItem) {
              internalErrors.push(errorItem);
            }
          }
        });
        numErrors += internalErrors.length - 1;
        return internalErrors.map((nestedItem) => {
          return `"${nestedItem.text}"`;
        });
      }
      return t(`paperworkPages.${i.text}`);
    });
  if (numErrors === errorItems.length) {
    if (numErrors > 1) {
      return `${t(`paperworkPages.mutipleErrors`)} ${errorItems.join(', ')}`;
    } else {
      return `${t(`paperworkPages.Please fix the error in the`)} ${errorItems[0]} ${t(
        `paperworkPages.field to proceed`
      )}`;
    }
  } else if (numErrors === 1) {
    return i18n.t(`paperworkPages.Please fix the error in the`);
  } else {
    return `${t(`paperworkPages.Please fix the error in the`)} ${numErrors} ${t(
      `paperworkPages.fields above to proceed`
    )}`;
  }
};

const PaperworkGroup: FC<PaperworkGroupInput> = ({
  items,
  pageId,
  defaultValues,
  options = {},
  onSubmit,
  saveProgress,
}) => {
  const [isSavingProgress, setIsSavingProgress] = useState(false);

  const { paperwork, allItems, saveButtonDisabled } = usePaperworkContext();

  // console.log('group param defaultValues', JSON.stringify(defaultValues));
  // console.log('paperwork', paperwork);
  const validationSchema = makeValidationSchema(items, pageId, {
    values: paperwork,
    items: allItems,
  }) as AnyObjectSchema;
  const methods = useForm({
    mode: 'onSubmit', // onBlur doesnt seem to work but we use onBlur of FormControl in NestedInput to implement the desired behavior
    reValidateMode: 'onChange',
    context: paperwork,
    defaultValues,
    shouldFocusError: true,
    resolver: yupResolver(validationSchema, { abortEarly: false }),
  });

  const theme = useTheme();

  const { watch, getValues, reset, formState } = methods;
  const { isSubmitting, isLoading, errors } = formState;

  useEffect(() => {
    if (items) {
      reset({
        ...(defaultValues ?? {}),
      });
    }
  }, [defaultValues, items, reset, pageId]);

  const errorMessage = makeFormErrorMessage(items, errors);

  const watchedFields = watch();
  const formValues: FieldValues = useMemo(() => {
    return { ...getValues(), ...watchedFields };
  }, [getValues, watchedFields]);

  useBeforeUnload(() => {
    saveProgress(formValues);
  });

  const submitHandler = useCallback(async () => {
    setIsSavingProgress(true);
    const { handleSubmit } = methods;
    await handleSubmit(onSubmit)();
    setIsSavingProgress(false);
  }, [methods, onSubmit]);

  const { bottomComponent, hideControls, controlButtons } = options;

  const swizzledCtrlButtons = useMemo(() => {
    const baseStuff = controlButtons ?? {};
    return {
      ...baseStuff,
      submitDisabled: baseStuff.loading || isLoading || saveButtonDisabled,
      onSubmit: submitHandler,
    };
  }, [controlButtons, isLoading, saveButtonDisabled, submitHandler]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={submitHandler}>
        <Grid container spacing={1}>
          <RenderItems items={items} />
        </Grid>
        <div id="page-form-inner-form" />
        {bottomComponent}
        {errorMessage && (
          <FormHelperText
            id={'form-error-helper-text'}
            sx={{ textAlign: 'right', color: theme.palette.error.main, gap: 0, mt: 1 }}
          >
            {errorMessage}
          </FormHelperText>
        )}
        {!hideControls && <ControlButtons {...swizzledCtrlButtons} loading={isSavingProgress || isSubmitting} />}
      </form>
    </FormProvider>
  );
};

interface RenderItemsProps {
  items: PMQuestionnaireItem[];
  parentItem?: PMQuestionnaireItem;
  fieldId?: string;
}

const RenderItems: FC<RenderItemsProps> = (props: RenderItemsProps) => {
  const { items, parentItem, fieldId } = props;
  const { paperwork, patient, allItems } = usePaperworkContext();

  const { watch, getValues } = useFormContext();

  // move this back up, yeah?
  const watchedFields = watch();
  const formValues: FieldValues = useMemo(() => {
    return { ...getValues(), ...watchedFields };
  }, [getValues, watchedFields]);
  const allFields: FieldValues = useMemo(() => {
    // console.log('paperwork', paperwork);
    // console.log('form values', formValues);
    const flatPaperwork = paperwork.flatMap((page) => page.item ?? []);
    const paperworkObj = flatPaperwork.reduce((accum, curr) => {
      accum[curr.linkId] = { ...curr };
      return accum;
    }, {} as any);
    return { ...paperworkObj, ...formValues };
  }, [paperwork, formValues]);

  const styledItems = (() => {
    const selectedItems = selectQuestionnaireItems(items, allItems, allFields);
    return applyItemStyleOverrides(selectedItems, allItems, allFields);
  })();

  useAutoFillValues({ questionnaireItems: items, allFields, formValues, fieldId, parentItem });

  return (
    <>
      {styledItems.map((item) => {
        if (item.type === 'display') {
          return <FormDisplayField item={item} key={`FDF-${item.linkId}`} patient={patient} />;
        } else if (item.type === 'group' && item.dataType !== 'DOB') {
          return (
            <RenderItems
              key={`group-root-${item.linkId}`}
              parentItem={item}
              items={item.item ?? []}
              fieldId={item.linkId}
            />
          );
        } else {
          const dependency = item.requireWhen ? formValues[item.requireWhen.question] : undefined;
          return (
            <NestedInput
              key={`NI-${item.linkId}`}
              item={item}
              inputProps={makeFormInputPropsForItem(item)}
              dependency={dependency}
              parentItem={props.parentItem}
            />
          );
        }
      })}
    </>
  );
};

// this probably has a more specific type but not sure what it is right now
const makeStyles = (): any => {
  const signatureFont = 'Dancing Script, Tangerine, Bradley Hand, Brush Script MT, sans-serif';
  return {
    signatureStyles: {
      input: {
        fontFamily: signatureFont,
        fontSize: '20px',
        fontWeight: 500,
      },
      'input::placeholder': {
        fontFamily: signatureFont,
      },
    },
  };
};

interface NestedInputProps extends StyledItemInputProps {
  dependency?: any;
  parentItem?: PMQuestionnaireItem;
}

const NestedInput: FC<NestedInputProps> = (props) => {
  const { item, inputProps, sx = {}, dependency, parentItem } = props;
  const { helperText, showHelperTextIcon } = inputProps || {};
  const { otherColors } = useIntakeThemeContext();
  const { trigger } = useFormContext();
  const [isFocused, setIsFocused] = useState(false);

  // fieldId returns the path to the scalar value (the thing that the inputs manipulate directly)
  // when using a group display, this path is used in lieu of the link id as the unique id for the field
  // because it is not mapped in the form values using its linkId and instead relies on the structural
  // identity provided by its field id to access and mutate the form. when the item isn't nested in a Group,
  // we simply use the linkId as the identifier. DOB groups are a special case.
  const fieldId = getPaperworkFieldId({ item, parentItem });
  const fieldName = parentItem ? fieldId : item.linkId;
  // console.log('item.linkId, fielId', item.linkId, fieldId);

  const { hasError, errorMessage } = useFieldError(parentItem ? fieldId : item.linkId);
  const { t } = useTranslation();

  useEffect(() => {
    if (hasError && dependency) {
      void trigger(fieldId);
    }
  }, [hasError, dependency, trigger, item.linkId, parentItem, fieldId]);

  return (
    <Grid item xs={12} md={item.width}>
      <Controller
        name={fieldName}
        render={(renderProps) => (
          <FormControl
            variant="standard"
            required={item.isRequired}
            error={hasError}
            fullWidth={item.isFullWidth}
            hiddenLabel={true}
            disabled={item.displayStrategy === 'protected'}
            onBlur={() => {
              if (getInputTypeForItem(item) === 'Text') {
                void trigger(fieldName);
              }
              setIsFocused(false);
            }}
            onChange={() => {
              if (hasError) {
                void trigger(fieldName);
              }
            }}
            onFocus={() => setIsFocused(true)}
            margin={'dense'}
            sx={{
              width: '100%',
              ...sx,
              '& .MuiInputBase-root': {
                marginTop: '0px',
              },
            }}
          >
            <BoldPurpleInputLabel
              id={`${item.linkId}-label`}
              htmlFor={`${item.linkId}-label`}
              sx={(theme) => ({
                ...(item.hideControlLabel ? { display: 'none' } : { whiteSpace: 'pre-wrap', position: 'unset' }),
                color: isFocused ? theme.palette.primary.main : theme.palette.primary.dark,
              })}
            >
              {item.infoText ? (
                <Tooltip enterTouchDelay={0} title={item.infoText} placement="top" arrow>
                  <Box>
                    {t(`paperworkPages.${item.text}`, { keySeparator: '.', nsSeparator: '.' })}
                    <IconButton>
                      <InfoOutlinedIcon sx={{ fontSize: '18px', color: 'secondary.main' }} />
                    </IconButton>
                  </Box>
                </Tooltip>
              ) : (
                t(`paperworkPages.${item.text}`, { keySeparator: '.', nsSeparator: '.' })
              )}
            </BoldPurpleInputLabel>
            <FormInputField renderProps={renderProps} itemProps={props} parentItem={parentItem} fieldId={fieldId} />
            {item.secondaryInfoText ? (
              <LightToolTip
                title={t(`paperworkPages.${item.secondaryInfoText}`)}
                placement="top"
                enterTouchDelay={0}
                backgroundColor={otherColors.toolTipGrey}
                color={otherColors.black}
              >
                <Box
                  sx={{
                    color: otherColors.scheduleBorder,
                    width: 'fit-content',
                    display: 'flex',
                    marginTop: 0.5,
                    cursor: 'default',
                  }}
                >
                  <InfoOutlinedIcon style={{ height: '16px', width: '16px' }} />
                  <Typography sx={{ fontSize: '14px', marginLeft: 0.5 }}>
                    {t('paperworkPages.Why do we ask this?')}
                  </Typography>
                </Box>
              </LightToolTip>
            ) : null}
            <FieldHelperText
              name={fieldId}
              hasError={hasError}
              helperText={helperText}
              showHelperTextIcon={showHelperTextIcon}
              errorMessage={t(`paperworkPages.${errorMessage}`)}
            />
          </FormControl>
        )}
      />
    </Grid>
  );
};

interface GetFormInputFieldProps {
  itemProps: StyledItemInputProps;
  renderProps: any; // do better
  fieldId: string;
  parentItem?: PMQuestionnaireItem;
}

const FormInputField: FC<GetFormInputFieldProps> = (
  { itemProps, renderProps, fieldId, parentItem },
  appointment: Appointment
): ReactElement => {
  const { item, inputProps } = itemProps;
  const { inputBaseProps, inputMode } = inputProps || { disableUnderline: true };
  const {
    field: { value, onChange, ref },
    formState: { defaultValues },
  } = renderProps;
  const inputType = getInputTypeForItem(item);
  const { otherColors } = useIntakeThemeContext();
  const theme = useTheme();
  const myInputComponent = inputBaseProps?.mask ? (InputMask as any) : 'input';

  const styles = useMemo(() => {
    return makeStyles();
  }, []);

  const inputSX = useMemo(() => {
    return SIGNATURE_FIELDS.includes(item.linkId)
      ? { ...styles.inputStyles, ...styles.signatureStyles }
      : styles.inputStyles;
  }, [item.linkId, styles.inputStyles, styles.signatureStyles]);

  const {
    onChange: smartOnChange,
    inputRef,
    value: unwrappedValue,
  } = usePaperworkFormHelpers({ item, renderValue: value, renderOnChange: onChange, fieldId });

  const { t } = useTranslation();

  // console.log('field id here', fieldId, parentItem?.linkId);
  const error = useFieldError(fieldId);
  // console.log('error check', fieldId, error);
  const answerOptions = useMemo(() => {
    const options = item.answerOption ?? [];
    if (!item.randomize) {
      return options;
    }

    // Durstenfeld shuffle alogrithm
    // https://stackoverflow.com/a/12646864
    for (let i = options.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [options[i], options[j]] = [options[j], options[i]];
    }
    return options;
  }, [item.answerOption, item.randomize]);
  const colorForButton = unwrappedValue ? theme.palette.destructive.main : theme.palette.primary.main;

  return (() => {
    switch (inputType) {
      case 'Text':
        return (
          <TextField
            id={fieldId}
            value={unwrappedValue}
            type={getUCInputType(item?.dataType)}
            aria-labelledby={`${item.linkId}-label`}
            aria-describedby={`${item.linkId}-helper-text`}
            inputProps={{
              ...inputBaseProps,
              inputMode:
                inputMode ??
                (item.type === 'integer' || item.type === 'decimal' || item.dataType === 'ZIP' ? 'numeric' : 'text'),
              ...(item.dataType === 'ZIP' && { pattern: '[0-9]*', maxLength: 5 }),
            }}
            placeholder={t(`paperworkPages.${item.placeholder}`)}
            required={item.required}
            onChange={smartOnChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              multiline: item.multiline,
              minRows: item.minRows,
              inputComponent: myInputComponent,
              disabled: item.displayStrategy !== 'enabled',
              ref: parentItem ? ref : inputRef,
              error: error.hasError,
            }}
            // this also overrides explicitly passed in sx in inputProps...
            sx={{
              ...inputSX,
              '.MuiOutlinedInput-root': {
                borderRadius: '8px',
                height: 'auto',
                width: '100%',
                padding: item.minRows ? '12px 16px' : '2px 2px',
                '&.Mui-focused': {
                  boxShadow: '0 -0.5px 0px 3px rgba(77, 21, 183, 0.25)',
                  '& fieldset': {
                    borderWidth: '1px',
                  },
                },
              },
            }}
            size="small"
          />
        );
      case 'Select':
        if (!item.answerOption) {
          throw new Error('No selectOptions given in select');
        }
        return (
          <SelectInput
            name={fieldId}
            value={t(`paperworkPages.${unwrappedValue}`)}
            placeholder={t(`paperworkPages.${item.placeholder}`)}
            defaultValue={(defaultValues && pickFirstValueFromAnswerItem(defaultValues[item.linkId])) ?? null}
            required={item.required}
            options={item.answerOption}
            inputRef={ref}
            onChange={smartOnChange}
          />
        );
      case 'Button':
        return (
          <Button
            variant="outlined"
            type="button"
            onClick={smartOnChange}
            sx={{
              color: colorForButton,
              borderColor: colorForButton,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
                borderColor: colorForButton,
              },
            }}
          >
            {t(`paperworkPages.${item.text}`, { keySeparator: '.', nsSeparator: '.' })}
          </Button>
        );
      case 'Checkbox':
        return (
          <FormControlLabel
            label={
              <Markdown components={{ p: DescriptionRenderer, a: LinkRenderer }}>
                {item.linkId === 'consent-to-treat' && appointment?.location?.address?.state === 'IL'
                  ? `${t('paperworkPages.illinoisConsentToTreat')}`
                  : `${t(`paperworkPages.${item.text}`, { keySeparator: '.', nsSeparator: '.' })}`}
              </Markdown>
            }
            sx={{ pt: item.hideControlLabel ? 0 : 1, alignItems: 'flex-start', margin: '0px' }}
            control={
              <Checkbox
                checked={unwrappedValue}
                color="primary"
                style={{ borderRadius: '4px' }}
                aria-label={`${item.linkId}-label`}
                sx={{
                  alignSelf: 'flex-start',
                  width: '18px',
                  height: '18px',
                  display: 'inline-flex',
                  marginTop: '0px',
                  marginRight: '10px',
                  '&.MuiCheckbox-root': {
                    borderRadius: '4px',
                  },
                  '&.Mui-checked': {
                    color: otherColors.purple,
                    borderRadius: '4px',
                    outline: '1px solid #4D15B7',
                  },
                }}
                onChange={smartOnChange}
                required={item.required}
              />
            }
          />
        );
      case 'Radio':
        return (
          <RadioInput
            name={fieldId}
            value={unwrappedValue}
            required={item.required}
            options={answerOptions}
            onChange={smartOnChange}
          />
        );
      case 'Radio List':
        return (
          <RadioListInput
            name={fieldId}
            value={unwrappedValue}
            required={item.required}
            options={answerOptions}
            onChange={smartOnChange}
          />
        );
      case 'Date':
        return <DateInput item={item} fieldId={parentItem ? fieldId : item.linkId} />;
      case 'Attachment':
        return (
          <FileInput
            fileName={item.linkId}
            fieldName={fieldId}
            value={unwrappedValue}
            onChange={smartOnChange}
            description={t(`paperworkPages.${item.attachmentText}`) ?? ''}
          />
        );
      case 'Group':
        return <RenderItems items={item.item ?? []} fieldId={fieldId} />;
      default:
        return <></>;
    }
  })();
};

interface FormDisplayFieldProps {
  item: StyledQuestionnaireItem;
  patient: PaperworkPatient | undefined;
}

const FormDisplayField: FC<FormDisplayFieldProps> = ({ item, patient }): ReactElement => {
  const displayType = inputTypeForDisplayItemId(item.linkId);
  const { t } = useTranslation();
  const element = useMemo(() => {
    switch (displayType) {
      case 'Header 4':
        return (
          <Box mb={1} key={`form-display-H4-${item.linkId}-${item.text}`}>
            <Typography variant="h4" color="primary">
              {t(`paperworkPages.${item.text}`, { keySeparator: '.', nsSeparator: '.' })}
            </Typography>
          </Box>
        );
      case 'Header 3':
        return (
          <Box mb={1} key={`form-display-H3-${item.linkId}-${item.text}`}>
            <Typography variant="h3" color="primary">
              {item.linkId === 'contact-page-address-text'
                ? `${patient?.firstName}${t('paperworkPages.s primary address')}`
                : t(`paperworkPages.${item.text}`, { keySeparator: '.', nsSeparator: '.' })}
            </Typography>
          </Box>
        );
      case 'Description':
        // eslint-disable-next-line no-case-declarations
        const descriptionText = t(`paperworkPages.${item.text}`, { keySeparator: '.', nsSeparator: '.' });
        console.log('description text:', item.text);
        return (
          <Typography
            variant="body1"
            key={`form-display-body1-${item.linkId}-${item.text}`}
            sx={{ paddingBottom: '10px' }}
          >
            {descriptionText}
          </Typography>
        );
      default:
        return <></>;
    }
  }, [displayType, item.linkId, item.text, patient?.firstName, t]);
  return (
    <Grid item xs={12} md={item.width} key={`form-display-field-${item.linkId}`} paddingLeft="0px">
      {element}
    </Grid>
  );
};

export default PaperworkGroup;
