import { ReactElement, useEffect } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { MixpanelContextProps, ScrollToTop, setupMixpanel, setupSentry } from 'ui-components';
import { IntakeThemeProvider } from './IntakeThemeProvider';
import { IntakeFlow } from './components';
import { GetReadyForVisit, NewUser, Version, BookingHome, Redirect, Reschedule } from './pages';
import Appointments from './pages/Appointments';
import CancellationConfirmation from './pages/CancellationConfirmation';
import CancellationReason from './pages/CancellationReason';
import CheckIn from './pages/CheckIn';
import ConfirmDateOfBirth from './pages/ConfirmDateOfBirth';
import { PaperworkHome, PaperworkPage } from './pages/PaperworkPage';
import PatientInformation from './pages/PatientInformation';
import Review from './pages/Review';
import ReviewPaperwork from './pages/ReviewPaperwork';
import ThankYou from './pages/ThankYou';
import WelcomeBack from './pages/WelcomeBack';
import { useAuth0 } from '@auth0/auth0-react';
import mixpanel from 'mixpanel-browser';

const isLowerEnvs =
  import.meta.env.MODE === 'dev' ||
  import.meta.env.MODE === 'staging' ||
  import.meta.env.MODE === 'testing' ||
  import.meta.env.MODE === 'training';

const isLowerEnvsOrProd = isLowerEnvs || import.meta.env.MODE === 'production';

if (isLowerEnvsOrProd) {
  setupSentry({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: import.meta.env.MODE,
    networkDetailAllowUrls: isLowerEnvs
      ? [import.meta.env.VITE_APP_FHIR_API_URL, import.meta.env.VITE_APP_PROJECT_API_URL]
      : [],
  });
}

// https://stackoverflow.com/a/51398471

export const bookingBasePath = '/location/:state/:slug/:visit_type';
export const paperworkBasePath = '/paperwork/:id';
export const visitBasePath = '/visit/:id';
export class IntakeFlowPageRoute {
  static readonly Welcome = new IntakeFlowPageRoute('/', <BookingHome />);
  static readonly Appointments = new IntakeFlowPageRoute('/visits', <Appointments />);

  // visit routes
  static readonly ThankYou = new IntakeFlowPageRoute(visitBasePath, <ThankYou />);
  static readonly CheckIn = new IntakeFlowPageRoute(`${visitBasePath}/check-in`, <CheckIn />);
  static readonly Reschedule = new IntakeFlowPageRoute(`${visitBasePath}/reschedule`, <Reschedule />);
  static readonly CancellationConfirmation = new IntakeFlowPageRoute(
    `${visitBasePath}/cancellation-confirmation`,
    <CancellationConfirmation />
  );
  static readonly CancellationReason = new IntakeFlowPageRoute(`${visitBasePath}/cancel`, <CancellationReason />);

  // booking routes
  static readonly WelcomeType = new IntakeFlowPageRoute(bookingBasePath, <BookingHome />);
  static readonly WelcomeBack = new IntakeFlowPageRoute(`${bookingBasePath}/patients`, <WelcomeBack />);
  static readonly GetReadyForVisit = new IntakeFlowPageRoute(`${bookingBasePath}/get-ready`, <GetReadyForVisit />);
  static readonly ConfirmDateOfBirth = new IntakeFlowPageRoute(
    `${bookingBasePath}/confirm-date-of-birth`,
    <ConfirmDateOfBirth />
  );
  static readonly PatientInformation = new IntakeFlowPageRoute(
    `${bookingBasePath}/patient-information`,
    <PatientInformation />
  );
  static readonly Review = new IntakeFlowPageRoute(`${bookingBasePath}/review`, <Review />);
  static readonly NewUser = new IntakeFlowPageRoute(`${bookingBasePath}/new-user`, <NewUser />);

  // paperwork routes
  static readonly PaperworkHomeRoute = new IntakeFlowPageRoute(paperworkBasePath, <PaperworkHome />);
  static readonly PaperworkInformation = new IntakeFlowPageRoute(`${paperworkBasePath}/:slug`, <PaperworkPage />);
  static readonly ReviewPaperwork = new IntakeFlowPageRoute(`${paperworkBasePath}/review`, <ReviewPaperwork />);

  private constructor(
    public readonly path: string,
    public readonly page: ReactElement
  ) {}
}

const MIXPANEL_SETTINGS: MixpanelContextProps = {
  token: import.meta.env.VITE_APP_MIXPANEL_TOKEN,
  registerProps: { appname: 'Urgent Care' },
};

setupMixpanel(MIXPANEL_SETTINGS);

export const FORM_PAGES = [
  IntakeFlowPageRoute.NewUser,
  IntakeFlowPageRoute.PatientInformation,
  IntakeFlowPageRoute.Review,
  IntakeFlowPageRoute.ReviewPaperwork,
  IntakeFlowPageRoute.ConfirmDateOfBirth,
];

function App(): JSX.Element {
  const { user } = useAuth0();
  useEffect(() => {
    mixpanel.identify();
  }, []);
  useEffect(() => {
    // user.name = user's verified phone number
    if (user?.name) {
      mixpanel.people.set({
        $phone: user.name,
      });
    }
  }, [user?.name]);
  return (
    <IntakeThemeProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path={'/version'} element={<Version />} />;
          <Route path={'/redirect'} element={<Redirect />} />;
          <Route path="/" element={<IntakeFlow />}>
            <Route path={IntakeFlowPageRoute.WelcomeType.path} element={IntakeFlowPageRoute.WelcomeType.page}>
              <Route path={IntakeFlowPageRoute.WelcomeBack.path} element={IntakeFlowPageRoute.WelcomeBack.page} />
              <Route
                path={IntakeFlowPageRoute.GetReadyForVisit.path}
                element={IntakeFlowPageRoute.GetReadyForVisit.page}
              />
              <Route path={IntakeFlowPageRoute.NewUser.path} element={IntakeFlowPageRoute.NewUser.page} />
              <Route
                path={IntakeFlowPageRoute.PatientInformation.path}
                element={IntakeFlowPageRoute.PatientInformation.page}
              />
              <Route
                path={IntakeFlowPageRoute.ConfirmDateOfBirth.path}
                element={IntakeFlowPageRoute.ConfirmDateOfBirth.page}
              />
              <Route path={IntakeFlowPageRoute.Review.path} element={IntakeFlowPageRoute.Review.page} />
            </Route>
            <Route path="*" element={<Navigate to="/"></Navigate>} />
            <Route path={IntakeFlowPageRoute.Appointments.path} element={IntakeFlowPageRoute.Appointments.page} />
            <Route
              path={IntakeFlowPageRoute.PaperworkHomeRoute.path}
              element={IntakeFlowPageRoute.PaperworkHomeRoute.page}
            >
              <Route
                path={IntakeFlowPageRoute.PaperworkInformation.path}
                element={IntakeFlowPageRoute.PaperworkInformation.page}
              />
              <Route
                path={IntakeFlowPageRoute.ReviewPaperwork.path}
                element={IntakeFlowPageRoute.ReviewPaperwork.page}
              />
            </Route>
            <Route path={IntakeFlowPageRoute.ThankYou.path} element={IntakeFlowPageRoute.ThankYou.page}>
              <Route path={IntakeFlowPageRoute.CheckIn.path} element={IntakeFlowPageRoute.CheckIn.page} />
              <Route path={IntakeFlowPageRoute.Reschedule.path} element={IntakeFlowPageRoute.Reschedule.page} />
              <Route
                path={IntakeFlowPageRoute.CancellationReason.path}
                element={IntakeFlowPageRoute.CancellationReason.page}
              />
              <Route
                path={IntakeFlowPageRoute.CancellationConfirmation.path}
                element={IntakeFlowPageRoute.CancellationConfirmation.page}
              />
            </Route>
          </Route>
        </Routes>
      </Router>
    </IntakeThemeProvider>
  );
}

export default App;
